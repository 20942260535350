import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { ParallaxProvider } from 'react-scroll-parallax'

import '../assets/index.scss'

import Rate from '../components/Rate'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Layout from '../components/Layout'
import {HTMLContent} from '../components/Content'

export const RatePageTemplate = ({
	title,
	subheading,
	body,
	fiveStarLink
}) => {
	return (
		<div className="page">
    	    <div className="page__header page__header--extras">
				<Navbar defaultDark={true} fixed={true}/>
				<main>
					<span className="heading" style={!subheading ? {paddingBottom: '6rem'} : {}}>{title}</span>
					{
						subheading &&
						<p className="subheading">{subheading}</p>
					}
				</main>
    	    </div>
			<section id="rate" className="alt-section">
				<div className="container">
					<h1>Please choose a rating below</h1>
					<Rate fiveStarLink={fiveStarLink}/>
				</div>
			</section>
			<section id="content" className="section">
				<div className="container">
					<HTMLContent className="secret-page__content" content={body}/>
				</div>
			</section>
			<Footer/>
    	</div>
	)
}

RatePageTemplate.propTypes = {
	title: PropTypes.string,
    body: PropTypes.string
}

const RatePage = ({ data }) => {
	const { frontmatter, html } = data.markdownRemark
	return (
		<ParallaxProvider>
			<Layout
        	    pageTitle={frontmatter.title}>
				<RatePageTemplate
					title={frontmatter.title}
					subheading={frontmatter.subheading}
					body={html}
					fiveStarLink={frontmatter.fiveStarLink}
				/>
			</Layout>
		</ParallaxProvider>
	)
}

RatePage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
			html: PropTypes.string
		}),
	}),
}

export default RatePage

export const pageQuery = graphql`
	query RatePageById($id: String!) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
			  title
			  subheading
			  fiveStarLink
			}
			html
		}
	}
`